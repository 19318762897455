<template>
  <v-main>
    <!-- <h3  style="color: black;" class="py-5 text-uppercase">ajout d'un marché</h3> -->

    <div class="mt-10">
      <ValidationObserver ref="form">
        <form @submit.prevent="validate('submit', marche)">
          <v-row align="center" justify="center" no-gutter>
            <v-col cols="6">
              <ValidationProvider name="nom du marche" rules="required">
                <v-text-field
                  v-model="marche.marche"
                  placeholder="nom du marché *"
                  class="text-field"
                  dense
                  flat
                  solo
                  hide-details
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <!-- <v-row align="center" justify="center" no-gutter>
            <v-col cols="6">
              <ValidationProvider
                name="prix de collecte d'un particulier"
                :rules="{
                  required: true,
                  regex: /^\d{1,10}([.,]\d{1,4})?$/,
                }"
              >
                <v-text-field
                  v-model="marche.prix_collecte_particulier"
                  placeholder="prix de collecte d'un particulier *"
                  class="text-field"
                  dense
                  flat
                  solo
                  hide-details
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row align="center" justify="center" no-gutter>
            <v-col cols="6">
              <ValidationProvider
                name="prix de collecte du collectif"
                :rules="{
                  regex: /^\d{1,10}([.,]\d{1,4})?$/,
                }"
              >
                <v-text-field
                  v-model="marche.prix_collecte_collectif"
                  placeholder="prix de collecte pour un collectif"
                  class="text-field"
                  dense
                  flat
                  solo
                  hide-details
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row> -->

          <v-row align="center" justify="center" no-gutter>
            <v-col cols="3" lg="3" md="3" sm="12" class="ml-sm-3 ml-md-0 ">
              <button class="addProduct">
                AJOUTER
              </button>
            </v-col>
          </v-row>
        </form>
      </ValidationObserver>
    </div>

    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        color="red darken-2"
        elevation="24"
      >
        {{ error }}
      </v-snackbar>
    </div>
  </v-main>
</template>

<script>
import apiMarches from "@/api/marches";

export default {
  name: "flotte",

  data() {
    return {
      snackbar: true,
      timeout: 3000,
      errors: [],
      marche: {
        marche: "",
        prix_collecte_particulier: "",
        prix_collecte_collectif: "",
      },
    };
  },

  components: {},

  props: {},

  methods: {
    validate: function(event) {
      console.log(event, "event");
      // console.log(event, this.$refs.form);
      // this.collectibles.push(marche)
      console.log(this.marche, "marche");
      if (event == "submit") {
        this.$refs.form.validate().then((valid) => {
          if (valid) {
            this.marche.prix_collecte_particulier = "";
            this.marche.prix_collecte_collectif = "";
            console.log(this.marche, "marche");

            this.createMarche(this.marche);
          } else {
            //display error
            console.log(valid);
            this.errors = [];
            let e = this.$refs.form.errors;
            for (const field in e) {
              e[field].forEach((msg) => {
                msg = msg.includes("invalide")
                  ? msg.split("est")[0] + "doit être un entier ou un décimal"
                  : msg;
                this.errors.push(msg);
              });

              this.snackbar = true;
            }
          }
        });
      }
    },
    createMarche(marche) {
      console.log("check marche", marche);
      apiMarches.createMarche(marche).then((res) => {
        console.log(res.data);
        this.marche.marche = "";
        this.marche.prix_collecte_particulier = "";
        this.marche.prix_collecte_collectif = "";

        this.$root.$emit("refreshMarketTab");
      });
      console.log(marche);
    },
  },
  mounted() {
    this.$root.$on("resetFormAddMarche", () => {
      this.marche.marche = "";
      this.marche.prix_collecte_particulier = "";
      this.marche.prix_collecte_collectif = "";
    });
  },
};
</script>

<style scoped>
.addProduct {
  width: 15vw;
  /* max-width: 200px; */
  height: 65px;
  border-radius: 15px;
  background-color: #af2c70;
  font: normal normal bold 14px/17px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
}

.v-input {
  border: 1px solid #e3e3e3 !important;
  border-radius: 15px !important;
  padding-left: 1vw;
  height: 55px;
}

.text-field,
.volume {
  font: italic normal 100 24px/30px Roboto;
  letter-spacing: 0px;
  color: #333333 !important;
  margin-left: 1vw;
  width: 100%;
}

::v-deep .text-field input::placeholder,
::v-deep .volume input::placeholder {
  font: italic normal bold 1.2rem/2.14rem Roboto;
  letter-spacing: 0px;
  color: #333333 !important;
}

::v-deep .v-text-field__slot {
  margin-left: 0px;
}

::v-deep .text-field input::placeholder,
::v-deep .volume input::placeholder {
  font-size: 1.45rem;
}
</style>
