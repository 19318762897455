<template>
  <v-main >
    <v-row no-gutters>
      <v-col cols="2">
        <span style="color: #AF2C70;" class="font-weight-bold">{{ title }}</span>
        <div class="line ml-2 mt-1"></div>
      </v-col>
    </v-row>

    <v-row no-gutter>
      <v-col cols="12">
        <v-row>
          <v-col class="item ma-2" cols="2" v-for="categorie in categories" :key="categorie.id_categorie">
            <div @click="searchProductCategorie(categorie)">
              <img
                  class="center-img"
                  v-bind:src="categorie.url_logo"
                  max-width="100"
                  max-height="100"
                  width="80"
                  height="80"
                  alt="cat_logo"
              />
              <p v-html="categorie.libelle" class="libelle_categorie"></p>
              <!-- <p class="libelle_categorie">{{ categorie.libelle }}</p> -->
            </div>         
          </v-col>
        </v-row>
      </v-col>
    </v-row>

   <v-row>
      <v-col v-for="btn in btn2" :key="btn.label" class="pl-0 text-left">
        <v-btn
          v-if="btn.method == 'addCat'"
          color="#cc0000"
          dark
          @click="showSecteurPop = true; titleSecteur = 'CRÉER UNE CATÉGORIE'"
          class="ml-3 my-3 font-weight-bold rounded-xl"
          >Ajouter une catégorie
        </v-btn>
      </v-col>
    </v-row>

    <ProductPop v-bind:dialog.sync="dialog" :categorie="categorie"/>
    <CategoriePop
      :showSecteurPop="showSecteurPop"
      @close="showSecteurPop = false"
      :fields="fields"
      :titleSecteur="titleSecteur"
      :btn2="btn2"
      @addCat="addCat"
    />

    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error.id"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        :color="color"
        elevation="24"
      >
        {{ error.msg }}
      </v-snackbar>
    </div>
  </v-main>
</template>

<script>
import apiCategories from "@/api/categories"
import ProductPop from "@/components/popups/SearchProductPopup"
import CategoriePop from "@/components/popups/secteurPop"

export default {
  name: "categories",

  data() {
    return {
      title: "TOUTES LES CATÉGORIES",
      btn2: [{ name: "Créer la catégorie et y ajouter des objets", method: "addCat" }],
      fields: [
        { label: "libelle", type: "text", value: "", placeholder: "Nom de la catégorie", name: "libelle", rules: "required" },
        { label: "document", type: "file", value: null, placeholder: "Picto", name: "document", rules: "required" }
      ],
      showSecteurPop: false,
      titleSecteur: "",

      categories: null,
      dialog: false,
      categorie: null,

      color: "",
      snackbar: false,
      timeout: 3000,
      errors: []
    };
  },
  
  components: {
    ProductPop,
    CategoriePop
  },

  async created() {
    this.getCategories()
  },

  methods: {
    getCategories() {
      apiCategories.getAllCategories().then((res) => {
        this.categories = res.data.categories

        this.categories.forEach((element) => {
          element["url_logo"] = process.env.VUE_APP_BASE_URL + element["url_logo"];
        });
      })
    },

    searchProductCategorie(categorie) {
      this.dialog = true;
      this.categorie = categorie;
    },

    addNewproduct() {
      this.dialog = false;
    },

    addCat(error) {
      if(error.valid) {
        let form = new FormData();
      
        console.log('fields: ', this.fields)
        this.fields.forEach(field => {
        form.append(field.label, field.value)
        })
        var config = {
          header: {
            "Content-Type": "multipart/form-data",
          },
        };
        console.log(form)
        apiCategories.createCategorie(form, config).then(() => {
          this.color = "#93b122";
          this.errors = []
          this.errors.push({msg:`La catégorie a bien été créée`,id:Math.random() * 100})
          this.snackbar = true;
          this.getCategories();
          this.showSecteurPop = false;
        }).catch(err => {
          if(err.response.status == 500) {
            this.color = "red darken-2";
            this.errors.push({msg:"Impossible de créér la catégorie",id:Math.random() * 100});
            this.snackbar = true;
          }
        })

        this.fields.forEach(field => {
        field.value = null
      })

      } else {
        console.log('erreur formulaire')
        this.color = "red darken-2";
        this.errors = error.errors;
        this.snackbar = true;
      }
    }
  },

  mounted() {}
    
};
</script>

<style scoped>
.line {
  background-color: #AF2C70;
  width: 40px;
  padding: 2px 0;
}

.center-img {
  margin-left: auto;
  margin-right: auto;
}

.libelle_categorie {
  font-family: "Roboto", sans-serif;
  color: #333333 !important;
  text-transform: uppercase;
  opacity: 1;
  font-weight: bold;
  font-size: 0.99rem !important;
  margin-top: -6px;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 18px;
}

.item {
  border: 1px solid #E3E3E3;
  border-radius: 10px;
}
</style>
