<template>
  <div>
    <v-overlay :value="show">
      <v-card
        v-if="showDeleteCommune == false && showDeleteCreneau == false"
        :class="this.switchPop ? 'size_card_2' : 'size_card_1'"
        light
      >
        <img id="close" src="@/assets/close.png" @click="$emit('close')" />
        <h3 class="mt-5 uppercasePopup title_back">{{ title }}</h3>
        <h4 class="mt-5"></h4>
        <v-row v-if="this.switchPop == false">
          <v-col cols="12" offset="">
            <v-data-table
              :headers="headers"
              :items="creneaux"
              :options.sync="option"
              hide-default-footer
              no-data-text="Aucune donnée disponible"
              class="ml-6 mx-4 font-weight-bold"
              style="color: #002b47"
            >
              <template class="" v-slot:[`item.horaires`]="{ item }">
                {{ item.horaires }}
              </template>
              <template v-slot:[`item.supprimer`]="{ item }">
                <div v-for="btn in btnCreneaux" :key="btn.name">
                  <v-btn
                    @click="frequenceToDelete = item.id_frequence, showDeleteCreneau = true"
                    text
                    v-if="item.horaires != '-' && btn.method == 'deleteCreneau'"
                    class="delete text-lowercase"
                    align="right"
                    >{{ btn.name }}</v-btn
                  >
                </div>
              </template>
            </v-data-table>
          </v-col>
          <v-col
            v-for="btn in btnCreneaux"
            :key="btn.name"
            class="mb-6"
            cols="6"
            offset="3"
          >
            <v-btn
              v-if="btn.method == 'addCreneaux'"
              block
              class="round"
              rounded
              color="#AF2C70"
              x-large
              dark
              depressed
              @click="creneauPop"
            >
              {{ btn.name }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <!-- popup suppression commune -->
      <v-card
        v-if="showDeleteCommune"
        class="modal_2 justify-center align-center"
        light
      >
        <v-row>
          <v-col cols="12">
            <h3 class="mt-5 title uppercasePopup">Supprimer cette commune ?</h3>
          </v-col>
          <v-col cols="2" offset="4">
            <v-btn
              v-on:click="
                deleteCommune(idSecteur, communeToDelete),
                  (showDeleteCommune = false)
              "
              color="success"
              x-large
              >oui</v-btn
            ></v-col
          >
          <v-col cols="2" @click="show2 = false"
            ><v-btn v-on:click="showDeleteCommune = false" color="error" x-large
              >non</v-btn
            ></v-col
          >
        </v-row>
      </v-card>

      <!-- popup suppression creneau -->
            <v-card
        v-if="showDeleteCreneau"
        class="modal_2 justify-center align-center"
        light
      >
        <v-row>
          <v-col cols="12">
            <h3 class="mt-5 title uppercasePopup">Supprimer ce créneau ?</h3>
          </v-col>
          <v-col cols="2" offset="4">
            <v-btn
              v-on:click="
                deleteCreneau(frequenceToDelete),
                  (showDeleteCreneau = false)
              "
              color="success"
              x-large
              >oui</v-btn
            ></v-col
          >
          <v-col cols="2" @click="show2 = false"
            ><v-btn v-on:click="showDeleteCreneau = false" color="error" x-large
              >non</v-btn
            ></v-col
          >
        </v-row>
      </v-card>


    </v-overlay>

    <v-overlay :value="overlay">

      <v-card light elevation="3" width="auto">

        <v-row>
          <v-col cols="8" offset="2" >
            <span class="font-weight-black">Les créneaux suivants n'ont pas pu être supprimés en raison de collectes prévus :</span>
          </v-col>

        </v-row>

        <v-row>
          <v-col cols="12">
                        <v-list-item >
              <v-list-item-content>
                <v-list-item-title v-for="date in creneauxNotDeleted" :key="date" >{{date}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

        </v-row>

              <v-btn
        class="white--text mb-3"
        color="#AF2C70"
        @click="overlay = false"
      >
        Fermer
      </v-btn>
        
      </v-card>

    </v-overlay>
    <!-- <SecteurPop
      :fields="fieldsCreneaux"
      :btn2="btnFrequence"
      :titleSecteur="titleCreneaux"
      :showSecteurPop="popupCreate"
      :days="days"
      :frequence="frequence"
      @close="
        popupCreate = false;
        $emit('show');
      "
      @createCreneau="createCreneau"
    /> -->

    <addCreneaux
      :fields="fieldsCreneaux"
      :btn2="btnFrequence"
      :titleSecteur="titleCreneaux"
      :showSecteurPop="popupCreate"
      :days="days"
      :frequence="frequence"
      @close="
        popupCreate = false;
        $emit('show');
        $root.$emit('refreshTitle', title)
      "
      @createCreneau="createCreneau"
    />

    <!-- Gestion des erreurs -->
    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        :color="color"
        elevation="24"
      >
        {{ error }}

              <!-- <template v-slot:action="{ attrs }">
        <v-btn
          color="indigo"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template> -->
      </v-snackbar>
    </div>

  </div>
</template>

<script>
// import FormAddCommunes from "../marche/formAddCommunes.vue";
// import AllCommunes from "../marche/allCommunes.vue";
// import SecteurPop from "@/components/popups/secteurPop";
// import addCreneaux from "@/components/admin/administration/marche";
import addCreneaux from "../marche/addCreneaux.vue"

import apiSecteur from "@/api/secteurs";
import apiCreneaux from "@/api/creneaux";

export default {
  components: {
    // FormAddCommunes,
    // AllCommunes,
    addCreneaux,
  },
  props: {
    btnCreneaux: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: () => false,
    },
    creneaux: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => "",
    },
    secteur: {
      type: Object,
      default: () => {},
    },
    btn: {
      type: String,
      default: () => "",
    },
    // nbpage: {
    //   type: Number,
    //   default: () => 0,
    // },
    // page: {
    //   type: Number,
    //   default: () => 1,
    // },
    idSecteur: {
      type: Number,
      default: () => 0,
    },
    // query: {
    //   type: String,
    //   default: () => "",
    // },
    showSecteurPop: {
      type: Boolean,
      default: () => false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },

    titleSecteur: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      headers: [
        { text: "JOUR", value: "jour", sortable: true },
        { text: "HORAIRES", value: "horaires", sortable: true },
        { text: "FRÉQUENCE", value: "frequence", sortable: true },
        { text: "DATE DEBUT", value: "date_debut", sortable: false },
        { text: "DATE FIN", value: "date_fin", sortable: false },
        { text: "", value: "supprimer", sortable: false },
      ],
      titleCreneaux: "",
      option: {},
      com: "",
      displayItem: 10,
      switchPop: false,
      showDeleteCommune: false,
      showDeleteCreneau: false,
      frequenceToDelete: 0,
      communeToDelete: 0,
      page: 1,
      totalItems: 0,
      nbpage: 1,
      popupCreate: false,
      overlay: false,
      creneauxNotDeleted: [],
      days: [
        { jour: 1, name: "lundi" },
        { jour: 2, name: "mardi" },
        { jour: 3, name: "mercredi" },
        { jour: 4, name: "jeudi" },
        { jour: 5, name: "vendredi" },
      ],
      frequence: [],
      fieldsCreneaux: [
        {
          name: "jour",
          placeholder: "Jour de la semaine *",
          rules: "required",
          value: "",
          type: "select",
          label: "jour",
        },
        {
          name: "date debut",
          placeholder: "Date de début *",
          rules: "required",
          value: "",
          type: "date",
          label: "date_debut",
        },
        {
          name: "date fin",
          placeholder: "Date de fin *",
          rules: "required",
          value: "",
          type: "date",
          label: "date_fin",
        },
        {
          name: "heure debut",
          placeholder: "Heure de début *",
          rules: "required",
          value: "",
          type: "time",
          label: "heure_debut",
        },
        {
          name: "heure fin",
          placeholder: "Heure de fin *",
          rules: "required",
          value: "",
          type: "time",
          label: "heure_fin",
        },
        {
          name: "fréquence",
          placeholder: "Choisir la fréquence *",
          rules: "required",
          value: "",
          type: "select",
          label: "id_occurence",
        },
        {
          name: "nombre de collectes",
          placeholder: "Nombre de collectes *",
          rules: "required",
          value: "",
          type: "number",
          label: "compteur",
        },
      ],
      btnFrequence: [{ name: "Créer le créneau", method: "createCreneau" }],
      errors: [],
      snackbar: false,
      color: "",
      timeout: 3000,
    };
  },
  methods: {
    creneauPop() {
      this.popupCreate = true;
      this.titleCreneaux = "Ajouter un créneau";
      this.show = false;
    },


  // Fetching the occurences from the API and storing them in the frequence variable.
    getOccurences() {
      apiCreneaux.getOccurences().then((res) => {
        this.frequence = res.data.occurences;
        // this.frequence = this.frequence.filter(freq => this.$moment(freq.date_fin) > this.$moment())
        console.log("occ ", this.frequence,Date());
      });
    },

    deleteCreneau(id_frequence) {
      apiSecteur
        .deleteFrequence(id_frequence)
        .then((res) => {
          console.log("deletetion ? ", res.data);

          if ( res.data.notDeleted.length == 0) {
            this.color = "#93b122";
            this.errors = [];
            this.errors.push(`Le créneau a bien été supprimé`);
            this.snackbar = true;
          }else {
            // this.color = "red darken-2";
            this.creneauxNotDeleted = [];
            res.data.notDeleted.forEach((creneau) => {
              this.creneauxNotDeleted.push(this.$moment(creneau.date).format("LL") )
            })
            this.overlay = true
            this.errors.push(this.creneauxNotDeleted);
            // this.multiLine = true
          }


          this.show = false;
          this.title = ""
          this.$root.$emit('refreshTitle', this.title)
        })
        .catch((err) => {
          if (err.status.response == 500) {
            this.color = "red darken-2";
            this.errors.push("Impossible de supprimer le créneau");
            this.snackbar = true;
          }
        });
    },

    createCreneau($event) {
      console.log($event, 'event')
      if ( $event.valid == false) {
        this.snackbar = true
        this.color = "red darken-2";
        this.errors = $event.errors
      }
      let data = {};
      this.fieldsCreneaux.forEach((field) => {
        data[field.label] = field.value;
      });
      console.log("fields creneaux ", this.fieldsCreneaux, data);

      data["id_secteur"] = this.idSecteur;
      data["id_camion"] = this.secteur.Camion.id_camion;

      apiCreneaux
        .createCreneau(data)
        .then((res) => {
          console.log("creneau created ", res.data);
          this.color = "#93b122";
          this.errors = [];
          this.errors.push(`Le créneau a bien été créé`);
          this.snackbar = true;
          this.popupCreate = false;
          this.fieldsCreneaux.forEach((field) => {
            field.value = "";
          });
          this.title = ""
          this.$root.$emit('refreshTitle', this.title)

        })
        .catch((err) => {
          if (err.response.status == 500) {
            this.color = "red darken-2";
            this.errors.push("Impossible de créer le créneau");
            this.snackbar = true;
          }
        });
    },
  },

  created() {
    // this.getAllCommunesBySecteur(this.idSecteur, this.query, this.page);
    this.getOccurences();
  },
};
</script>

<style lang="css" scoped>
.delete {
  color: #af2c70;
  text-decoration: underline;
  cursor: pointer;
}
.title_back {
  background-color: #002b47;
  color: white;
  border-radius: 17px 17px 0px 0px;
  padding: 10px;
  font-size: 15px;
}
.v-card.v-sheet.theme--light {
  border-radius: 17px;
}
.v-card {
  width: 58.71vw;
  max-width: 1088px;
  max-height: 700px;
  position: relative;
}
.size_card_1 {
  height: auto;
  padding: 0vw;
}
.size_card_2 {
  height: auto;
  padding: 0vw;
}
#close {
  position: absolute;
  right: -15px;
  top: -35px;
}
h3 {
  font-size: 1.3rem;
  font-weight: bold;
}
h4 {
  font-size: 2.1rem;
  font-weight: bold;
}
.v-btn.round {
  border-radius: 12px;
  font-weight: bold;
  font-size: 1em;
}
.theme--light.v-text-field--outlined >>> fieldset {
  border-color: #e8e8e8;
}
.v-textarea >>> textarea::placeholder {
  font-size: 24px;
  font-style: italic;
  font-weight: thin;
  color: #333;
  opacity: 1;
}
.uppercasePopup {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

.v-data-table >>> thead tr th span {
  font-weight: bold;
  font-size: 14px;
  color: #002b47;
}


.v-data-table >>> th:nth-child(4), .v-data-table >>> th:nth-child(5),.v-data-table >>> th:nth-child(6){
  width: 12%;
}

.v-data-table >>> tr {
  margin: 10px 0;
  background-color: #fff;
}

.v-data-table >>> td {
  text-align: center;
  background-color: #f2f2f2;
}
</style>
