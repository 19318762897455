import axios from "axios"

const RESSOURCE_NAME = "creneaux"

export default {
    getCreneaux(id_marche, index) {
        return axios.get(`${RESSOURCE_NAME}/getcreneaux/${id_marche}/${index}`)
    },

    getCreneauxAdmin(index) {
        return axios.get(`${RESSOURCE_NAME}/getcreneaux_admin/${index}`)
    },

    updateCamion(id_creneau, id_camion) {
        return axios.patch(`${RESSOURCE_NAME}/changecamion/${id_creneau}/${id_camion}`)
    },

    createCreneau(creneau) {
        return axios.post(`${RESSOURCE_NAME}/setcreneaux/`, { createCreneaux: creneau })
    },

    getOccurences() {
        return axios.get(`${RESSOURCE_NAME}/getoccurences`)
    },

    deleteCreneau(id_creneau) {
        return axios.delete(`${RESSOURCE_NAME}/delete/${id_creneau}`)
    }
}