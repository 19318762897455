<template>
  <v-main full-width class="admini ">
    <v-row>
      <v-col cols="12">
        <p class="title ml-6 pt-4">{{title}}</p>
        <div class="line ml-6"></div>
      </v-col>
      <v-row> 
          <v-col class="pl-10">
              <v-row>
                  <v-col class="pt-13 pr-2">
                      <v-col :class="menu.marche.img == true ? 'back_pink border_card' : 'border_card' " @click="turnImg('marche')"> 
                            <v-col class="pa-0 img_position">
                                <img v-if="menu.marche.img==true" src="@/assets/images/administration/marche-blanc.png" alt="Logo marché" />
                                <img v-if="menu.marche.img ==false" src="@/assets/images/administration/marche-rose.png" alt="Logo marché" />
                            </v-col>
                            <v-col class="pt-0 title_card"> MARCHÉS</v-col>
                      </v-col>
                  </v-col>
              </v-row>
          </v-col>
          <v-col>
                <v-row>
                  <v-col class="pt-13 pr-2 pl-2">
                      <v-col :class="menu.utilisateur.img == true ? 'back_pink border_card' : 'border_card' " @click="turnImg('utilisateur')"> 
                            <v-col class="pa-0 img_position">
                                <img v-if="menu.utilisateur.img==true" src="@/assets/images/administration/utilisateurs-blanc.png" alt="Logo marché" />
                                <img v-if="menu.utilisateur.img ==false" src="@/assets/images/administration/utilisateurs-rose.png" alt="Logo marché" />
                            </v-col>
                            <v-col class="pt-0 title_card"> UTILISATEURS</v-col>
                      </v-col>
                  </v-col>
              </v-row>
          </v-col>
          <v-col>
              <v-row>
                  <v-col class="pt-13 pr-2 pl-2">
                      <v-col :class="menu.flotte.img == true ? 'back_pink border_card' : 'border_card' " @click="turnImg('flotte')"> 
                            <v-col class="pa-0 img_position">
                                <img v-if="menu.flotte.img==true" src="@/assets/images/administration/flotte-blanc.png" alt="Logo marché" />
                                <img v-if="menu.flotte.img ==false" src="@/assets/images/administration/flotte-rose.png" alt="Logo marché" />
                            </v-col>
                            <v-col class="pt-0 title_card"> FLOTTE</v-col>
                      </v-col>
                  </v-col>
              </v-row>
          </v-col>
          <v-col class="pr-10">
              <v-row>
                  <v-col class="pt-13 pl-2">
                      <v-col :class="menu.categories.img == true ? 'back_pink border_card' : 'border_card' " @click="turnImg('categories')"> 
                            <v-col class="pa-0 img_position">
                                <img v-if="menu.categories.img==true" src="@/assets/images/administration/categories-blanc.png" alt="Logo marché" />
                                <img v-if="menu.categories.img ==false" src="@/assets/images/administration/categorie-rose.png" alt="Logo marché" />
                            </v-col>
                            <v-col class="pt-0 title_card"> CATÉGORIES</v-col>
                      </v-col>
                  </v-col>
              </v-row>
          </v-col>
      </v-row>
    </v-row>
    <v-row>
        <v-col class="px-0 pl-10 pr-10">
            <Marche v-if="menu.marche.img"/>
            <Utilisateurs v-if="menu.utilisateur.img"/>
            <Flotte v-if="menu.flotte.img"/>
            <Categories v-if="menu.categories.img"/>
        </v-col>
    </v-row>
  </v-main>
</template>

<script>
import Marche from "../administration/marche.vue";
import Utilisateurs from "../administration/utilisateurs.vue";
import Flotte from "../administration/flotte.vue";
import Categories from "../administration/categories.vue";
export default {
  name: "OngletsAdministration",

  data() {
    return {
        menu: {
        marche:{img:true, title:"marchés"},
        utilisateur: {img:false, title:"utilisateurs"},
        flotte: {img:false, title:"flotte"},
        categories: {img:false, title:"categories"},
        },
        title:"marchés"
    };
  },

  components: {
      Marche, 
      Utilisateurs,
      Flotte,
      Categories
  },

  props: {},

  methods: {
      turnImg(e){
        this.menu.marche.img=false;
        this.menu.utilisateur.img=false;
        this.menu.flotte.img=false;
        this.menu.categories.img=false;
        this.menu[e].img =true
        this.title = this.menu[e].title
        console.log("alex",this.$refs.someName);
      }
  },
};
</script>

<style scoped>
main.v-main {
    background-color: white !important;
    border-radius: 15px ;

}



.admini{
    padding: 0 !important;
    margin: 0 !important;
}
.img_position{
    margin-top: -17px;

}
.title_card{
    margin-top: -33px;
    font-weight: bold;
    font-size: 17px;

}
.border_card{
    border: 1px solid #E0E0E0;
    border-radius: 11px ;
}
.back_pink{
    background-color: #AF2C70;
    color: white;
    font-weight: bold;
    font-size: 17px;
}
.title {
  text-align: left;
  color: #93b121;
  text-transform: uppercase;
  opacity: 1;
  font: normal normal bold 1.14285714rem Roboto;
  margin-bottom: 0px;
}

.line {
  text-align: left;
  padding: 2px 0;
  width: 3%;
  background-color: #93b121;
  align-self: center;
  margin-top: 0.9vh;
  margin-bottom: -2vh;
}
</style>
