<template>
  <v-main>
    <v-tabs v-model="currentTab" class="pt-10" grow>
      <!-- <v-col :class="{active: currentTab === item.id}" class="border" v-for="item in items" :key="item.id" @change="updateCurrentTab(item.id)" >
        <v-tab>
          <v-col cols="12" class="title_onglet pt-0 pb-0">{{ item.tab }}</v-col>
        </v-tab>
      </v-col> -->
      <v-tab
        v-for="(marche, i) in marches"
        :key="marche.id_marche"
        :class="i < marches.length - 1 ? 'mr-1' : ''"
        @click="
          getSecteurs(marche.id_marche);
          currentMarcheItem = marche;
        "
      >
        <v-col class="title_onglet title_1 pt-0 pb-0">
          {{ marche.marche }} <v-divider></v-divider
        ></v-col>
      </v-tab>

      <!-- <v-tab class="mr-1">
          <v-col  class="title_onglet title_1 pt-0 pb-0">CCVE</v-col>
        </v-tab>

        <v-tab class="mr-1" >
          <v-col  class="title_onglet title_2 pt-0 pb-0">SIREDOM</v-col>
        </v-tab>
    
        <v-tab >
          <v-col  class="title_onglet title_3 pt-0 pb-0">RECYCLERIE</v-col>
        </v-tab>
       -->
      <!-- <v-tab class="ml-1">
          <v-col  class="title_onglet title_4 pt-0 pb-0"
            >AJOUTER UN MARCHÉ
            </v-col >
        </v-tab> -->

      <v-tabs-items v-model="currentTab" class="mb-16" :eager="true">
        <v-tab-item class="ml-0" v-for="item in items" :key="item.id.length">
          <v-card flat class="fixPadding">
            <v-card-text>
              <component
                :secteurs="secteurs"
                v-bind:is="item.content"
                :id_marche="item.id"
                :marche="currentMarcheItem"
              ></component>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-main>
</template>
<script>
import Ccve from "../administration/marche/ccve.vue";
import AddMarche from "../administration/marche/addMarche.vue";
import apiMarches from "@/api/marches";

export default {
  name: "marche",

  data() {
    return {
      tab: null,
      text: "blablab",
      items: [
        // { tab: "CCVE", content: "Ccve", id: 0 },
        // { tab: "SIREDOM", content: "Siredom", id: 1 },
        // { tab: "RECYCLERIE", content: "Recyclerie", id: 2 },
        // { tab: "AJOUTER UN MARCHÉ", content: "AddMarche", id: 3 },
      ],
      currentTab: 0,
      marches: [],
      currentMarche: 0,
      secteurs: [],
      currentMarcheItem: {},
    };
  },

  components: {
    Ccve,
    AddMarche,
  },

  props: {},

  methods: {
    updateCurrentTab($event) {
      this.currentTab = $event;
      console.log(this.currentTab, "current tab");

      //   this.$store.commit("changeOnglet",$event)
    },
    getAllMarches() {
      this.items = [];
      apiMarches.getAllMarches().then((res) => {
        this.marches = res.data.marches;
        this.marches.forEach((mar) => {
          this.items.push({
            tab: mar.marche,
            content: "Ccve",
            id: mar.id_marche,
          });
        });
        this.items.push({
          tab: "AJOUTER UN MARCHÉ",
          content: "AddMarche",
          id: this.items.length,
        });
        this.marches.push({ id_marche: null, marche: "AJOUTER UN MARCHE" });
        console.log(this.items, "itemmsss");

        this.getSecteurs(this.marches[0].id_marche);
        console.log(this.currentMarche, "current marche ");
        this.currentMarcheItem = this.marches[0];
      });
    },
    getSecteurs(id_marche) {
      apiMarches.getAllSecteurs(id_marche).then((res) => {
        console.log("alex api");
        this.secteurs = res.data.secteurs;
        console.log(this.secteurs);
        this.$root.$emit("resetFormAddMarche");
      });
    },
  },
  created() {
    this.getAllMarches();
  },
  mounted() {
    this.$root.$on("refreshMarketTab", () => {
      this.currentTab = 0;
      return this.getAllMarches();
    });
  },
};
</script>

<style scoped>
.border {
  background-color: #e0e0e0;
}
.border.active {
  background-color: white;
}
.v-tabs-bar__content:nth-child(2) {
  background: red;
  color: red !important;
}

.title_1 {
  /* max-width: 40%; */
}
.title_2 {
  max-width: 50%;
}
.title_3 {
  max-width: 55%;
}
.title_4 {
  max-width: 70%;
}
.v-tab.v-tab--active .title_onglet {
  /* border-bottom: 9px solid #af2c70; */
  color: #002b47;
}
.v-tab:not(.v-tab--active) .title_onglet {
  /* border-bottom: 9px solid #af2c70; */
  color: #8fa0ab;
}

.v-tab--active div .v-divider {
  max-width: 50%;
  border-width: 3px;
  text-align: center;
  margin: auto;
  background-color: #af2c70;
  margin-top: 5px;
}
.v-tab:not(.v-tab--active) div .v-divider {
  display: none;
}
.title_onglet {
  font-weight: bold;
}
::v-deep .v-tabs-slider {
  display: none;
}
.title_onglet.v-tab.v-tab--active {
  color: #333333;
}
.v-tab {
  background-color: #e0e0e0;
  border-radius: 15px 15px 0 0;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
}

.v-tab--active {
  background-color: white;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: white;
}
.v-card__text {
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0 0 15px 15px !important;
}

.fixPadding {
  padding: 0vw !important;
}
</style>
