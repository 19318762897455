<template>
  <v-main>
    <v-data-table
      :headers="headers"
      :items="datas"
      :items-per-page="displayItem"
      :options.sync="options"
      :server-items-length="totalItems"
      :page="page"
      hide-default-header
      hide-default-footer
      no-data-text="Aucune donnée disponible"
      class="font-weight-bold"
    >
      <template v-slot:[`item.modifier`]="{ item }">
        <a style="color: #AF2C70; text-decoration: underline" @click="$emit('modifier', item.id_utilisateur)">modifier</a>
      </template>

      <template v-slot:[`item.logo`]="{}">
        <img width="25px" src="@/assets/images/clients/bonhommeVert.png" />
      </template>
    </v-data-table>

    <v-pagination
      v-if="!hidePagination"
      v-model="page"
      :length="nbPage"
      circle
      class="mt-6"
    ></v-pagination>
  </v-main>
</template>

<script>
export default {
  name: "t",
  data() {
    return {
      options: {},
    };
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    datas: {
      type: Array,
      default: () => [],
    },
    displayItem: {
      type: Number,
      default: () => 99,
    },
    page: {
      type: Number,
      default: () => 1,
    },
    totalItems: {
      type: Number,
      default: () => 0,
    },

    nbPage: {
      type: Number,
      default: () => 1,
    },
    hidePagination: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style scoped>
::v-deep tbody tr:nth-child(2n) {
  background-color: #f8f8f8;
}
</style>
