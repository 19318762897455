<template>
  <div>
    <v-overlay :value="show">
      <v-card
        v-if="showDeleteCommune == false"
        :class="this.switchPop ? 'size_card_2' : 'size_card_1'"
        light
      >
        <img id="close" src="@/assets/close.png" @click="$root.$emit('refreshTitle', ''), $emit('close')" />
        <h3 class="mt-5 uppercasePopup title_back">{{ title }}</h3>
        <h4 class="mt-5"></h4>
        <v-col cols="12" v-if="this.switchPop == false">
          <v-text-field
            solo
            dense
            flat
            placeholder="Rechercher une commune par nom ou code postal"
            class="autocomplete ml-6 mx-4"
            v-model="querySearch"
          >
            <template class="searchField" slot="append">
              <img class="search-logo" src="@/assets/search.png" />
            </template>
          </v-text-field>
        </v-col>
        <v-row v-if="this.switchPop == false">
          <v-col cols="12" offset="">
            <v-data-table
              :headers="headers"
              :items="communes"
              :options.sync="option"
              hide-default-footer
              no-data-text="Aucune donnée disponible"
              class="ml-6 mx-4"
            >
              <template v-slot:[`item.commune`]="{ item }">
                {{ item.commune }}
              </template>
              <template v-slot:[`item.code_postal`]="{ item }">
                {{ item.code_postal }}
              </template>
              <template v-slot:[`item.supprimer`]="{ item }">
                <div
                  v-if="item"
                  @click="
                    (showDeleteCommune = true),
                      (communeToDelete = item.id_commune)
                  "
                  class="delete"
                  align="right"
                >
                  retirer
                </div>
              </template>
            </v-data-table>

            <v-pagination
              v-model="pagination"
              :length="nbpage"
              circle
              class="my-6"
            ></v-pagination>
          </v-col>
          <v-col class="mb-6" cols="6" offset="3">
            <v-btn
              block
              class="round"
              rounded
              color="#AF2C70"
              x-large
              dark
              depressed
              @click="
                switchPop = true;
                getAllCommunes('', 0, idSecteur);
              "
            >
              {{ btn }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="this.switchPop == true">
          <AllCommunes
            @backToSearch="backToSearchFunction"
            :allCommunes="allCommunes"
            :query="query"
            @querySearch="searchCommune($event)"
            @addCommune="addCommune($event)"
            @ReturnCommuneSecteur="
              switchPop = false;
              getAllCommunesBySecteur(idSecteur, '', 0);
            "
            :page="page"
            :nbpage="nbpage"
            @createCommune="
              popupCreate = true;
              $emit('close');
            "
            @changePage="searchCommune($event)"
            @SearchCommune="searchCommune($event)"
          />
        </v-row>
        <v-row v-if="popupCreate">
          <!-- <FormAddCommunes
          :idSecteur="idSecteur"
          @backToSearch="backToSearchFunction"
        /> -->
        </v-row>
      </v-card>

      <v-card
        v-if="showDeleteCommune == true"
        class="modal_2 justify-center align-center"
        light
      >
        <v-row>
          <v-col cols="12">
            <h3 class="mt-5 title uppercasePopup">
              Retirer cette commune ?
            </h3>
          </v-col>
          <v-col cols="2" offset="4">
            <v-btn
              v-on:click="
                deleteCommune(idSecteur, communeToDelete),
                  (showDeleteCommune = false)
              "
              color="success"
              x-large
              >oui</v-btn
            ></v-col
          >
          <v-col cols="2" @click="show2 = false"
            ><v-btn v-on:click="showDeleteCommune = false" color="error" x-large
              >non</v-btn
            ></v-col
          >
        </v-row>
      </v-card>
    </v-overlay>
    <SecteurPop
      :fields="fieldsCreateCommune"
      :btn2="btnCreateCommune"
      :titleSecteur="titleCreateCommune"
      :showSecteurPop="popupCreate"
      @close="
        popupCreate = false;
        $emit('show');
        searchCommune({ query: query, page: 0 });
      "
      @createCommune="createCommune"
    />
    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        :color="color"
        elevation="24"
      >
        {{ error }}
      </v-snackbar>
    </div>
  </div>
</template>

<script>
// import FormAddCommunes from "../marche/formAddCommunes.vue";
import AllCommunes from "../marche/allCommunes.vue";
import apiSecteur from "@/api/secteurs";
import SecteurPop from "@/components/popups/secteurPop";

export default {
  components: {
    // FormAddCommunes,
    AllCommunes,
    SecteurPop,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "",
    },
    secteur: {
      type: Object,
      default: () => {},
    },
    // communes: {
    //   type: Array,
    //   default: () => [],
    // },

    commentaire: {
      type: String,
      default: () => "",
    },
    btn: {
      type: String,
      default: () => "",
    },
    // nbpage: {
    //   type: Number,
    //   default: () => 0,
    // },
    // page: {
    //   type: Number,
    //   default: () => 1,
    // },
    idSecteur: {
      type: Number,
      default: () => 0,
    },
    // query: {
    //   type: String,
    //   default: () => "",
    // },
    showSecteurPop: {
      type: Boolean,
      default: () => false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },

    titleSecteur: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      headers: [
        { text: "COMMUNE", value: "commune", sortable: true },
        { text: "CODE POSTAL", value: "code_postal", sortable: true },
        // { text: "", align: "", value: "", sortable: false },
        // { text: "", align: "", value: "", sortable: false },
        // { text: "", align: "", value: "", sortable: false },
        { text: "", value: "supprimer", sortable: false },
      ],
      option: {},
      com: "",
      displayItem: 10,
      switchPop: false,
      backToSearch: false,
      showDeleteCommune: false,
      communeToDelete: 0,
      allCommunes: [],
      query: "",
      page: 1,
      totalItems: 0,
      nbpage: 1,
      communes: [],
      popupCreate: false,
      fieldsCreateCommune: [
        {
          name: "commune",
          placeholder: "Nom de la commune *",
          rules: "required",
          value: "",
          type: "text",
          label: "commune",
        },
        {
          name: "code_postal",
          placeholder: "Code postal de la commune *",
          rules: "required|max:5|alpha_num",
          value: "",
          type: "text",
          label: "code postal",
        },
      ],
      btnCreateCommune: [
        { name: "Créer une commune", method: "createCommune" },
      ],
      titleCreateCommune: "Ajouter une nouvelle commune",
      errors: [],
      snackbar: false,
      color: "",
      timeout: 3000,
    };
  },
  methods: {
    deleteCommune(id_secteur, id_commune) {
      // console.log(id_secteur, id_commune);
      // console.log("communes length", this.communes.length)
      // console.log("current page", this.page)
      apiSecteur.deleteCommune(id_secteur, id_commune).then(() => {
        if ( this.communes.length == 1) { // page precedente si plus d'item sur la page courante
          this.getAllCommunesBySecteur(id_secteur, this.query, this.page - 2);
        } else {
          this.getAllCommunesBySecteur(id_secteur, this.query, this.page - 1);
        }
      });
    },
    trigger() {
      this.switchPop = false;
    },
    backToSearchFunction() {
      console.log("back to search in popup marche");
      this.switchPop = false;
      this.$emit("reloadSearch");
      // il faut recharger les communes et repartir à la page 1
    },
    getAllCommunes(query, page, idSecteur) {
      apiSecteur
        .getAllCommunes(query, page, idSecteur)
        .then((res) => {
          this.query = query;
          this.allCommunes = res.data.communes.rows;
          this.page = res.data.communes.currentPage + 1;
          this.totalItems = res.data.communes.totalItems;
          this.nbpage = res.data.communes.totalPages;
          console.log(
            "alexxx",
            this.page,
            res.data.communes.rows,
            "page getallcommune"
          );
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    searchCommune(searchObj) {
      this.getAllCommunes(searchObj.query, searchObj.page, this.idSecteur);
    },
    addCommune(commune) {
      this.errors = [];
      console.log("add commune", commune);
      apiSecteur.addCommune(commune.id_commune, this.idSecteur).then(() => {
        console.log("ok add commune");
        this.snackbar = false;
        this.color = "#93b122";
        let msg = "La commune " + commune.commune + " a été ajoutée au secteur";
        this.errors.push(msg);
        this.snackbar = true;
        this.getAllCommunes(this.query, this.page - 1, this.idSecteur);
      });
    },
    getAllCommunesBySecteur(id_secteur, query, page) {
      apiSecteur
        .getCommunes(id_secteur, query, page)
        .then((res) => {
          this.query = query;
          this.communes = res.data.communes.rows;
          this.page = res.data.communes.currentPage + 1;
          this.totalItems = res.data.communes.totalItems;
          this.nbpage = res.data.communes.totalPages;
          console.log(this.communes, "page getallcommuneBySecteur", this.page);
          console.log(this.errors, "errrrrrrrrrrrrrrr");
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    createCommune(error) {
      if (error.valid) {
        this.color = "#93b122";
        this.errors = [
          "La commune " + this.fieldsCreateCommune[0].value + " a été crée",
        ];
        this.snackbar = true;
        let data = {};
        this.fieldsCreateCommune.forEach((field) => {
          data[field.name] = field.value;
        });

        apiSecteur.createCommune(this.idSecteur, data).then(() => {
          this.popupCreate = false;
          this.$emit("show");
          this.searchCommune({ query: this.query, page: 0 });
        });
      } else {
        this.color = "red darken-2";
        this.errors = error.errors;
        this.snackbar = true;
      }
      console.log(this.fieldsCreateCommune, "create commune", error);
    },
  },

  computed: {
    pagination: {
      get() {
        return this.page;
      },
      set(newvalue) {
        this.page = newvalue - 1;
        this.getAllCommunesBySecteur(this.idSecteur, this.query, this.page);
      },
    },
    querySearch: {
      get() {
        return this.query;
      },
      set(newvalue) {
        console.log("change query", newvalue, this.page);
        this.query = newvalue;
        this.getAllCommunesBySecteur(this.idSecteur, this.query, this.page - 1);
      },
    },
  },
  watch: {
    idSecteur(nv, ov) {
      console.log(nv, ov, "change id secteur", this.page);
      this.getAllCommunesBySecteur(this.idSecteur, this.query, this.page - 1);
    },
  },
  created() {
    // this.getAllCommunesBySecteur(this.idSecteur, this.query, this.page);
  },
};
</script>

<style lang="css" scoped>
.delete {
  color: #af2c70;
  text-decoration: underline;
  cursor: pointer;
}
.title_back {
  background-color: #002b47;
  color: white;
  border-radius: 17px 17px 0px 0px;
  padding: 10px;
  font-size: 11px;
}
.v-card.v-sheet.theme--light {
  border-radius: 17px;
}
.v-card {
  width: 58.71vw;
  max-width: 1088px;
  max-height: 700px;
  position: relative;
}
.size_card_1 {
  height: auto;
  padding: 0vw;
}
.size_card_2 {
  height: auto;
  padding: 0vw;
}
#close {
  position: absolute;
  right: -15px;
  top: -35px;
}
h3 {
  font-size: 1.3rem;
  font-weight: bold;
}
h4 {
  font-size: 2.1rem;
  font-weight: bold;
}
.v-btn.round {
  border-radius: 12px;
  font-weight: bold;
  font-size: 1em;
}
.theme--light.v-text-field--outlined >>> fieldset {
  border-color: #e8e8e8;
}
.v-textarea >>> textarea::placeholder {
  font-size: 24px;
  font-style: italic;
  font-weight: thin;
  color: #333;
  opacity: 1;
}
.uppercasePopup {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.v-data-table >>> th:nth-child(1) {
  width: 40%;
}
.v-data-table >>> th:nth-child(2) {
  width: 40%;
}
</style>
