<template>
  <v-main>
    <v-row>
      <v-col class="pl-0">
        <tableComponent
          class="mt-8"
          :datas="users"
          :totalItems="users.length"
          :headers="headers"
          :hidePagination="hidePagination"
          @modifier="
            modifierUser($event);
            titleUser = 'Modifier un utilisateur';
          "
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pl-0 text-left">
        <v-btn
          color="#cc0000"
          dark
          @click="
            createUserForm();
            titleUser = 'Créer un nouvel utilisateur';
          "
          class="ml-3 my-3 font-weight-bold rounded-xl"
          >Ajouter un utilisateur
        </v-btn>
      </v-col>
    </v-row>

    <SecteurPop
      :fields="fields"
      :btn2="btn"
      :roles="roles"
      :titleSecteur="titleUser"
      :showSecteurPop="show"
      @close="show = false"
      @update="update"
      @create="createUser"
    />
    <div id="snack">
      <v-snackbar
        v-for="error in errors"
        :key="error.id"
        v-model="snackbar"
        :timeout="timeout"
        right
        rounded="pill"
        :color="color"
        elevation="24"
      >
        {{ error.msg }}
      </v-snackbar>
    </div>
  </v-main>
</template>

<script>
import api from "@/api/users";
import tableComponent from "@/components/shared/table";
import SecteurPop from "@/components/popups/secteurPop";

import rolesApi from "@/api/roles";
export default {
  name: "utilisateur",

  data() {
    return {
      users: [],
      headers: [
        { text: "", align: "start", value: "logo", sortable: false },
        { text: "NOM PRENOM", value: "user_info", sortable: true },
        { text: "TELEPHONE", value: "telephone_fixe", sortable: true },
        { text: "EMAIL", value: "email", sortable: true },
        { text: "ROLES", value: "role", sortable: true },
        {
          text: "",
          align: "start",
          value: "modifier",
          sortable: false,
          method: "modifier",
        },
      ],
      hidePagination: true,
      show: false,

      fields: [],
      btn: [],
      roles: [],
      user: {},
      titleUser: "Créer un nouvel utilisateur",
      snackbar: false,
      timeout: 3000,
      errors: [],
      color: "",
    };
  },

  components: {
    tableComponent,
    SecteurPop,
  },

  props: {
    computed: {
      allRoles() {
        return this.roles;
      },
    },
  },

  methods: {
    async getAllUsers() {
      this.users = await api.getAllUsers();
      this.users = this.users.data.users;
      this.users.forEach((user) => {
        user["user_info"] =
          user.civilite.charAt(0).toUpperCase() +
          user.civilite.slice(1) +
          " " +
          user.nom.toUpperCase() +
          " " +
          user.prenom.charAt(0).toUpperCase() +
          user.prenom.slice(1);
        user["role"] = user.Role.role;
      });
      console.log(this.users);
    },
    createUser(error) {
      this.errors = [];
      if (error.valid) {
        let data = {};
        this.fields.forEach((field) => {
          data[field.name] = field.value;
        });

        api
          .createUser(data)
          .then(() => {
            this.color = "#93b122";
            this.errors = [ {msg:
              "L'utilisateur " +
                this.fields[1].value +
                " " +
                this.fields[2].value +
                " a été crée",id:Math.random() * 100}
            ];
            this.snackbar = true;
            this.getAllUsers();
            this.show = false;
          })
          .catch((err) => {
            if (err.response.status == 500) {
              this.color = "red darken-2";

              this.errors.push({
                msg: "Un utilisateur avec cette adresse email existe déjà.",
                id: Math.random() * 1000,
              });
              this.snackbar = true;
            }
          });
      } else {
        this.color = "red darken-2";
        this.errors = error.errors;
        this.snackbar = true;
      }
    },
    async getRoles() {
      this.roles = await rolesApi.getRoles();
      this.roles = this.roles.data.roles;
      console.log(this.roles);
    },
    async modifierUser(id) {
      console.log(id, "modifier le user");
      this.user = await api.getOneUser(id);
      console.log(this.user);
      this.user = this.user.data.user;
      this.fields = [
        {
          label: "civilite",
          value: this.user.civilite,
          type: "radio",
          options: [
            { label: "Mr", value: "Mr" },
            { label: "Mme", value: "Mme" },
          ],
          name: "civilite",
          rules: "required",
        },
        {
          value: this.user.nom,
          type: "text",
          name: "nom",
          rules: "required",
          placeholder: "Nom de l'utilisateur *",
          label: "nom de l'utilisateur",
        },
        {
          label: "prenom",
          value: this.user.prenom,
          type: "text",
          name: "prenom",
          rules: "required",
          placeholder: "Prénom de l'utilisateur *'",
        },
        {
          label: "email",
          value: this.user.email,
          type: "email",
          name: "email",
          rules: "required|email",
          placeholder: "Email de l'utilisateur *'",
        },
        {
          label: "telephone fixe",
          value: this.user.telephone_fixe,
          type: "text",
          name: "telephone_fixe",
          placeholder: "Téléphone de l'utilisateur *",
        },
        {
          label: "Role",
          value: this.user.Role.id_role,
          type: "select",
          txt: "role",
          val: "id_role",
          name: "id_role",
          rules: "required",
          placeholder: "Role de l'utilisateur *'",
        },
      ];
      this.btn = [
        { name: "Modifier un utilisateur", method: "update", type: "btn" },
      ];

      this.show = true;
      console.log(this.user);
    },
    cancel() {
      this.show = false;
    },
    update(error) {
      this.errors = [];
      if (error.valid) {
        let data = {};
        this.fields.forEach((field) => {
          data[field.name] = field.value;
        });

        api
          .updateUser(data, this.user.id_utilisateur)
          .then(() => {
            this.getAllUsers();
            this.show = false;
            this.color = "#93b122";
            this.errors = [
              {
                msg:
                  "L'utilisateur " +
                  this.fields[1].value +
                  " " +
                  this.fields[2].value +
                  " a été modifié",
                id: Math.random() * 1000,
              },
            ];
            this.snackbar = true;
          })
          .catch((err) => {
            if (err.response.status == 500) {
              this.color = "red darken-2";
              this.errors = [];
              this.errors.push({
                msg: "Un utilisateur avec cette adresse email existe déjà.",
                id: Math.random() * 100,
              });
              this.snackbar = true;
            }
          });
      } else {
        this.color = "red darken-2";
        this.errors = error.errors;
        this.snackbar = true;
      }
    },
    createUserForm() {
      console.log("emty fields");
      this.btn = [
        { name: "Créer un utilisateur", method: "create", type: "btn" },
      ];

      this.fields = [
        {
          label: "civilite",
          value: "",
          type: "radio",
          options: [
            { label: "Mr", value: "Mr" },
            { label: "Mme", value: "Mme" },
          ],
          name: "civilite",
          rules: "required",
        },
        {
          label: "nom",
          value: "",
          type: "text",
          name: "nom",
          rules: "required",
          placeholder: "nom de l'utilisateur *",
        },
        {
          label: "prenom",
          value: "",
          type: "text",
          name: "prenom",
          rules: "required",
          placeholder: "prénom de l'utilisateur *",
        },
        {
          label: "email",
          value: "",
          type: "email",
          name: "email",
          rules: "required|email",
          placeholder: "email de l'utilisateur *",
        },
        {
          label: "telephone fixe",
          value: "",
          type: "text",
          name: "telephone_fixe",
          placeholder: "téléphone de l'utilisateur *",
        },
        {
          label: "Role",
          value: "",
          type: "select",
          txt: "role",
          val: "id_role",
          name: "id_role",
          rules: "required",
          placeholder: "rôle de l'utilisateur *",
        },
      ];
      this.show = true;
    },
  },
  async created() {
    await this.getAllUsers();
    await this.getRoles();
  },
};
</script>

<style scoped></style>
